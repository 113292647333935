import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle_button", "state", "drop_down"]

  connect() {
    console.log('connected to toggle controller')
  }

  toggle(event) {
    let button = event.currentTarget
    if(!this.stateTarget.checked)
      this.activate_toggle(button)
    else
      this.reset_toggle(button)
  }

  activate_toggle(button) {
    button.classList.remove('inactive_toggle_button')
    this.toggle_buttonTargets[0].classList.remove('inactive_span_translate')
    this.toggle_buttonTargets[1].classList.remove('inactive_span_opacity')
    this.toggle_buttonTargets[2].classList.remove('inactive_span_toggler')
    button.classList.add('active_toggle_button')
    this.toggle_buttonTargets[0].classList.add('active_span_translate')
    this.toggle_buttonTargets[1].classList.add('active_span_opacity')
    this.toggle_buttonTargets[2].classList.add('active_span_toggler')
    this.stateTarget.checked = true
  }

  reset_toggle(button) {
    button.classList.remove('active_toggle_button')
    this.toggle_buttonTargets[0].classList.remove('active_span_translate')
    this.toggle_buttonTargets[1].classList.remove('active_span_opacity')
    this.toggle_buttonTargets[2].classList.remove('active_span_toggler')
    button.classList.add('inactive_toggle_button')
    this.toggle_buttonTargets[0].classList.add('inactive_span_translate')
    this.toggle_buttonTargets[1].classList.add('inactive_span_opacity')
    this.toggle_buttonTargets[2].classList.add('inactive_span_toggler')
    this.stateTarget.checked = false
  }

  toggle_close() {
    this.drop_downTarget.classList.toggle('hidden')
  }
}