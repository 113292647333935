import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import { setTimeout } from "core-js"

export default class extends Controller {
  static targets = ["upload_filename", "upvote", "upvote_thumbs_up"]

  connect() {
    console.log('connected to utility controller')
  }

  upload_file(event) {
    let element = event.currentTarget
    if(element.files[0]) {
      this.upload_filenameTarget.innerHTML = element.files[0].name
      this.upload_filenameTarget.classList.remove('text-gray-500')
      this.upload_filenameTarget.classList.add('text-green-500')
    }
    else {
      this.upload_filenameTarget.innerHTML = 'no file selected'
      this.upload_filenameTarget.classList.remove('text-indigo-500')
      this.upload_filenameTarget.classList.add('text-green-500')
    }
  }

  upvote_blog_post(event) {
    var key = event.currentTarget.getAttribute("data-post-key")
    var upvote_target = this.upvoteTarget
    var upvote_thumbs_up = this.upvote_thumbs_upTarget
    upvote_thumbs_up.classList.remove("text-gray-500")
    upvote_thumbs_up.classList.add("text-indigo-700")
    upvote_thumbs_up.classList.add("animate-bounce")
    Rails.ajax({
      type: "POST",
      url: "/blog_posts/"+key+"/upvote",
      success: function(response) {
        upvote_target.textContent = " +"+response.votes
        setTimeout(() => upvote_thumbs_up.classList.remove("animate-bounce"), 2000)
      },
      error: function(response){
        upvote_target.textContent = " +"+response.votes
        setTimeout(() => upvote_thumbs_up.classList.remove("animate-bounce"), 2000)
      }
    })
  }
}