import { Controller } from "@hotwired/stimulus"
import { setTimeout } from "core-js"

export default class extends Controller {
  static targets = ["sidebar"]

  connect() {
    console.log('connected to scroll utility controller')
  }

  sidebar_on_scroll() {
    var body = document.body
    var html = document.documentElement
    var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
    var effective_height = height - window.innerHeight-700
    if((window.pageYOffset - effective_height)>=-300 && (window.pageYOffset - effective_height)<-150) {
      this.reduce_sidebar_opacity_60()
    } else if((window.pageYOffset - effective_height)>=-150 && (window.pageYOffset - effective_height)<0) {
      this.reduce_sidebar_opacity_30()
    } else if((window.pageYOffset - effective_height)>=0) {
      this.reduce_sidebar_opacity()
    } else {
      this.show_sidebar()
    }
  }

  reduce_sidebar_opacity_60() {
    this.sidebarTarget.classList.remove('opacity-30')
    this.sidebarTarget.classList.remove('opacity-60')
    this.sidebarTarget.classList.remove('hidden')
    this.sidebarTarget.classList.add('opacity-60')
  }

  reduce_sidebar_opacity_30() {
    this.sidebarTarget.classList.remove('opacity-30')
    this.sidebarTarget.classList.remove('opacity-60')
    this.sidebarTarget.classList.remove('hidden')
    this.sidebarTarget.classList.add('opacity-30')
  }

  reduce_sidebar_opacity() {
    this.sidebarTarget.classList.add('hidden')
  }

  show_sidebar() {
    this.sidebarTarget.classList.remove('hidden')
    this.sidebarTarget.classList.remove('opacity-60')
    this.sidebarTarget.classList.remove('opacity-30')
  }

}